"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dumpSqlCommand = exports.dumpSqlInsert = exports.dumpSqlDelete = exports.dumpSqlUpdate = exports.dumpSqlSelect = void 0;
const dumpSqlExpression_1 = require("./dumpSqlExpression");
const dumpSqlSource_1 = require("./dumpSqlSource");
const dumpSqlCondition_1 = require("./dumpSqlCondition");
function dumpSqlSelect(dmp, cmd) {
    dmp.put('^select ');
    if (cmd.distinct) {
        dmp.put('^distinct ');
    }
    if (cmd.topRecords) {
        if (!dmp.dialect.rangeSelect || dmp.dialect.offsetFetchRangeSyntax)
            dmp.put('^top %s ', cmd.topRecords);
    }
    if (cmd.selectAll) {
        dmp.put('* ');
    }
    if (cmd.columns && cmd.columns.length > 0) {
        if (cmd.selectAll)
            dmp.put('&n,');
        dmp.put('&>&n');
        dmp.putCollection(',&n', cmd.columns, fld => {
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, fld);
            if (fld.alias)
                dmp.put(' ^as %i', fld.alias);
        });
        dmp.put('&n&<');
    }
    dmp.put('^from ');
    (0, dumpSqlSource_1.dumpSqlFromDefinition)(dmp, cmd.from);
    if (cmd.where) {
        dmp.put('&n^where ');
        (0, dumpSqlCondition_1.dumpSqlCondition)(dmp, cmd.where);
        dmp.put('&n');
    }
    if (cmd.groupBy) {
        dmp.put('&n^group ^by ');
        dmp.putCollection(', ', cmd.groupBy, expr => (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, expr));
        dmp.put('&n');
    }
    if (cmd.having) {
        dmp.put('&n^having ');
        (0, dumpSqlCondition_1.dumpSqlCondition)(dmp, cmd.having);
        dmp.put('&n');
    }
    if (cmd.orderBy) {
        dmp.put('&n^order ^by ');
        dmp.putCollection(', ', cmd.orderBy, expr => {
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, expr);
            dmp.put(' %k', expr.direction);
        });
        dmp.put('&n');
    }
    if (cmd.range) {
        if (dmp.dialect.offsetFetchRangeSyntax) {
            dmp.put('^offset %s ^rows ^fetch ^next %s ^rows ^only', cmd.range.offset, cmd.range.limit);
        }
        else {
            dmp.put('^limit %s ^offset %s ', cmd.range.limit, cmd.range.offset);
        }
    }
    if (cmd.topRecords) {
        if (dmp.dialect.rangeSelect && !dmp.dialect.offsetFetchRangeSyntax)
            dmp.put('^limit %s ', cmd.topRecords);
    }
}
exports.dumpSqlSelect = dumpSqlSelect;
function dumpSqlUpdate(dmp, cmd) {
    dmp.put('^update ');
    (0, dumpSqlSource_1.dumpSqlSourceRef)(dmp, cmd.from);
    dmp.put('&n^set ');
    dmp.put('&>');
    dmp.putCollection(', ', cmd.fields, col => {
        dmp.put('%i=', col.targetColumn);
        (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, col);
    });
    dmp.put('&<');
    if (cmd.where) {
        dmp.put('&n^where ');
        (0, dumpSqlCondition_1.dumpSqlCondition)(dmp, cmd.where);
        dmp.put('&n');
    }
}
exports.dumpSqlUpdate = dumpSqlUpdate;
function dumpSqlDelete(dmp, cmd) {
    dmp.put('^delete ^from ');
    (0, dumpSqlSource_1.dumpSqlSourceRef)(dmp, cmd.from);
    if (cmd.where) {
        dmp.put('&n^where ');
        (0, dumpSqlCondition_1.dumpSqlCondition)(dmp, cmd.where);
        dmp.put('&n');
    }
}
exports.dumpSqlDelete = dumpSqlDelete;
function dumpSqlInsert(dmp, cmd) {
    if (cmd.insertWhereNotExistsCondition) {
        dmp.put('^insert ^into %f (%,i) ^select ', cmd.targetTable, cmd.fields.map(x => x.targetColumn));
        dmp.putCollection(',', cmd.fields, x => (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, x));
        if (dmp.dialect.requireFromDual) {
            dmp.put(' ^from ^dual ');
        }
        dmp.put(' ^where ^not ^exists (^select * ^from %f ^where ', cmd.targetTable);
        (0, dumpSqlCondition_1.dumpSqlCondition)(dmp, cmd.insertWhereNotExistsCondition);
        dmp.put(')');
    }
    else {
        dmp.put('^insert ^into %f (%,i) ^values (', cmd.targetTable, cmd.fields.map(x => x.targetColumn));
        dmp.putCollection(',', cmd.fields, x => (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, x));
        dmp.put(')');
    }
}
exports.dumpSqlInsert = dumpSqlInsert;
function dumpSqlCommand(dmp, cmd) {
    switch (cmd.commandType) {
        case 'select':
            dumpSqlSelect(dmp, cmd);
            break;
        case 'update':
            dumpSqlUpdate(dmp, cmd);
            break;
        case 'delete':
            dumpSqlDelete(dmp, cmd);
            break;
        case 'insert':
            dumpSqlInsert(dmp, cmd);
            break;
        case 'allowIdentityInsert':
            dmp.allowIdentityInsert(cmd.targetTable, cmd.allow);
            break;
    }
}
exports.dumpSqlCommand = dumpSqlCommand;
