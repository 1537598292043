import { __awaiter } from "tslib";
import { getOpenedTabs, openedTabs } from '../stores';
import _ from 'lodash';
export class LoadingToken {
    constructor() {
        this.isCanceled = false;
    }
    cancel() {
        this.isCanceled = true;
    }
}
export function sleep(milliseconds) {
    return new Promise(resolve => window.setTimeout(() => resolve(null), milliseconds));
}
export function changeTab(tabid, changeFunc) {
    openedTabs.update(files => files.map(tab => (tab.tabid == tabid ? changeFunc(tab) : tab)));
}
export function markTabUnsaved(tabid) {
    const tab = getOpenedTabs().find(x => x.tabid == tabid);
    if (tab.unsaved)
        return;
    openedTabs.update(files => files.map(tab => (tab.tabid == tabid ? Object.assign(Object.assign({}, tab), { unsaved: true }) : tab)));
}
export function markTabSaved(tabid) {
    openedTabs.update(files => files.map(tab => (tab.tabid == tabid ? Object.assign(Object.assign({}, tab), { unsaved: false }) : tab)));
}
export function setSelectedTabFunc(files, tabid) {
    return [
        ...(files || []).filter(x => x.tabid != tabid).map(x => (Object.assign(Object.assign({}, x), { selected: false }))),
        ...(files || []).filter(x => x.tabid == tabid).map(x => (Object.assign(Object.assign({}, x), { selected: true }))),
    ];
}
export function setSelectedTab(tabid) {
    openedTabs.update(tabs => setSelectedTabFunc(tabs, tabid));
}
export function getObjectTypeFieldLabel(objectTypeField, driver) {
    var _a;
    if (objectTypeField == 'matviews')
        return 'Materialized Views';
    if (objectTypeField == 'collections')
        return (_a = _.startCase(driver === null || driver === void 0 ? void 0 : driver.collectionPluralLabel)) !== null && _a !== void 0 ? _a : 'Collections/Containers';
    return _.startCase(objectTypeField);
}
export function asyncFilter(arr, predicate) {
    return __awaiter(this, void 0, void 0, function* () {
        const results = yield Promise.all(arr.map(predicate));
        return arr.filter((_v, index) => results[index]);
    });
}
export function isMac() {
    var _a;
    // @ts-ignore
    const platform = (navigator === null || navigator === void 0 ? void 0 : navigator.platform) || ((_a = navigator === null || navigator === void 0 ? void 0 : navigator.userAgentData) === null || _a === void 0 ? void 0 : _a.platform) || 'unknown';
    return platform.toUpperCase().indexOf('MAC') >= 0;
}
export function formatKeyText(keyText) {
    if (isMac()) {
        return keyText
            .replace('CtrlOrCommand+', '⌘ ')
            .replace('Shift+', '⇧ ')
            .replace('Alt+', '⌥ ')
            .replace('Command+', '⌘ ')
            .replace('Ctrl+', '⌃ ')
            .replace('Backspace', '⌫ ');
    }
    return keyText.replace('CtrlOrCommand+', 'Ctrl+');
}
export function resolveKeyText(keyText) {
    if (isMac()) {
        return keyText.replace('CtrlOrCommand+', 'Command+');
    }
    return keyText.replace('CtrlOrCommand+', 'Ctrl+');
}
export function isCtrlOrCommandKey(event) {
    if (isMac()) {
        return event.metaKey;
    }
    return event.ctrlKey;
}
