import _ from 'lodash';
import { recentDatabases, currentDatabase, getRecentDatabases } from '../stores';
import registerCommand from './registerCommand';
import { getConnectionLabel } from 'dbgate-tools';
currentDatabase.subscribe(value => {
    if (!value)
        return;
    recentDatabases.update(list => {
        const res = [
            value,
            ..._.compact(list).filter(x => { var _a, _b; return x.name != value.name || ((_a = x.connection) === null || _a === void 0 ? void 0 : _a._id) != ((_b = value.connection) === null || _b === void 0 ? void 0 : _b._id); }),
        ].slice(0, 10);
        return res;
    });
});
function switchDatabaseCommand(db) {
    return {
        text: `${db.name} on ${getConnectionLabel(db === null || db === void 0 ? void 0 : db.connection, { allowExplicitDatabase: false })}`,
        onClick: () => currentDatabase.set(db),
    };
}
registerCommand({
    id: 'database.switch',
    category: 'Database',
    name: 'Change to recent',
    menuName: 'Switch recent database',
    keyText: 'CtrlOrCommand+D',
    getSubCommands: () => getRecentDatabases().map(switchDatabaseCommand),
});
